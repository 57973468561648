import {mapActions, mapGetters} from "vuex";
import VueSocialSharing from 'vue-social-sharing'
import VueHead from 'vue-head'
import Vue from 'vue'

Vue.use(VueSocialSharing)
Vue.use(VueHead)

export default {
  name: "is-news",
  data(){
    return{
      location:'',
      params: {
        title: '',
        description: '',
        keywords: '',
        image: ''
      },
    }
  },
  head: {
    title: function () {
      return {
        inner: this.params.title !== '' ? this.params.title : '',
        separator: ' ',
      }
    },
    meta: function () {
      return [
        {n: 'keywords', content: this.params.keywords, id: 'keywords'},
        {p: 'og:title', content: this.params.title, id: 'og:title'},
        {p: 'og:description', content: this.params.description, id: 'og:description'},
        {n: 'title', content: this.params.title , id: 'title'},
        {n: 'description', content: this.params.description , id: 'description'},
        {p: 'og:image', content: this.params.image, id: 'og:image'},
      ]
    },
    link: function () {
      let route = this.$route.path
      if (route.slice(1, 4) === 'ru/' || route.slice(1, 4) === 'en/'){
        route = route.slice(3)
      }
      return [
        { rel: 'canonical', href: window.location.origin + this.$route.path, id: 'canonical' },
        {rel: 'alternate', hreflang: 'ru', href: window.location.origin + '/ru' + route, id: 'ru'},
        {rel: 'alternate', hreflang: 'en', href: window.location.origin+ '/en' + route, id: 'en'},
        {rel: 'alternate', hreflang: 'x-default', href: window.location.origin + route, id: 'x-default'},
      ]
    }

  },
  computed: {
    ...mapGetters({
      oneNews: 'setting/oneNews'
    })
  },
  watch: {
    oneNews: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.setMeta()
        }
      }
    }
  },
  created() {
    this.location = window.location.href
    this.fetchOneNews(this.$route.params.slug)
    },
  methods: {
    ...mapActions({
      fetchOneNews: `setting/GET_ONE_NEWS`
    }),
    setMeta() {
      this.params.title = this.oneNews.meta_title !== '' ? this.oneNews.meta_title : 'ICHIBAN STORE',
        this.params.description = this.oneNews.meta_description !== '' ? this.oneNews.meta_description : 'ICHIBAN STORE ICHIBAN STORE ICHIBAN STORE ICHIBAN STORE',
        this.params.keywords = this.oneNews.meta_keywords,
        this.params.image = this.oneNews.image !== '' ? this.oneNews.image : 'https://ichibanstore.jp/logo.png'
      this.$emit('updateHead')
    },
  }
}